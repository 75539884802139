import { Box, Divider, Typography } from "@material-ui/core"
import React, { useEffect, useRef } from "react"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"
import { RichText } from "prismic-reactjs"
import { DEFAULT_BLUE } from "../styles/common"
import styled from "styled-components"

export const query = graphql`
  {
    prismic {
      allBokas {
        edges {
          node {
            info
          }
        }
      }
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
  background-color: ${DEFAULT_BLUE};
  color: #d5d5d5;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 450;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  h6 {
    font-size: 2rem;
  }
  h5 {
    font-size: 2.2rem;
  }
  p {
    font-size: 1.6rem;
  }
`
const Boka = ({ data }) => {
  const iframeRef = useRef(null)
  const info = data.prismic.allBokas.edges[0].node.info

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
    window.BOKAMERA = {
    "configuration": {
        "bookMethods": [
            "contact-information",
            "login",
            "login-facebook"
        ],
        "bookLayout": "service-based",
        "timesLayout": "week",
        "listingLayout": "rows",
        "language": "sv",
        "topOffset": 0,
        "preventAutoscroll": false,
        "scrollAfterStep": 0,
        "company": "6c6d657f-dfd4-41c9-bcca-99f5b5e35776",
        "showNextAvailableTime": true,
        "hideServiceImage": false,
        "showEndTimeOnTimeslots": false,
        "darkTheme": false,
        "translations": []
    }
}`
    document.body.appendChild(script);
    const script2 = document.createElement('script');

    script2.src = "https://embed.bookmore.com/v2/embed.js";
    script2.async = true;

    document.body.appendChild(script2);
  }, [])
  return (
    <Layout transparent currentSiteUrl='/boka'>
      <SEO title='Boka' />
      <div id="bokamera-embedded"></div>
      <script src="https://embed.bookmore.com/v2/embed.js"></script>
      <Divider />
      <Wrapper>
        <Typography variant='h2'>Bokningsinformation</Typography>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          padding='45px'
          maxWidth='800px'
        >
          {RichText.render(info)}
        </Box>
      </Wrapper>
    </Layout>
  )
}
export default Boka
